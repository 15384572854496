@import 'magic';
html {
    &.has-scroll-smooth {
        overflow: hidden;
    }
}
.has-scroll-smooth {
    body {
        overflow: hidden;
    }
    [data-scroll-container] {
        min-height: var(--window-height, 100vh);
        transition: 400ms min-height;
    }
}
[data-scroll-section] {
    will-change: transform, opacity;
}
[data-scroll-direction='horizontal'] {
    main {
        max-width: inherit;
    }
    [data-scroll-container] {
        white-space: nowrap;
        height: var(--window-height, 100vh);
        transition: 400ms height;
        display: inline-block;
    }
    [data-scroll-section] {
        display: inline-block;
        vertical-align: top;
        white-space: nowrap;
        height: 100%;
        opacity: 1 !important;
    }
}
.c-scrollbar {
    position: absolute;
    right: 0;
    top: 0;
    width: rem(16);
    height: 100%;
    transform-origin: right center;
    transition: border-width 0.3s, opacity 0.3s;
    opacity: 0;
    z-index: 1000;
    &:hover, &:active {
        .c-scrollbar_thumb {
            border-width: rem(6);
        }
    }
    &:hover, .has-scroll-dragging &, .has-scroll-scrolling & {
        opacity: 1;
    }
    [data-scroll-direction='horizontal'] & {
        width: 100%;
        height: rem(16);
        top: auto;
        bottom: 0;
    }
}
.c-scrollbar_thumb {
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0.1;
    left: 0;
    border-radius: rem(20);
    cursor: grab;
    transition: opacity 0.3s, border-width 0.3s;
    border: rem(7) solid transparent;
    background: sc(b) content-box;
    [data-scroll-direction='horizontal'] & {
        right: auto;
        bottom: 0;
    }
    .has-scroll-dragging & {
        cursor: grabbing;
        opacity: 0.2;
    }
}
[data-scroll] {
    will-change: transform;
}
