@import 'magic';
:root {
    --main-font-size: 10px;
    --side-gap: #{rem(36)};
    @media screen and (max-height: 570px){
        --side-gap: #{rem(20)};
    }
    @media screen and (orientation: landscape) {
        @media screen and (max-width: 1000px){
            font-size: 8px;
        }
        @media screen and (max-width: 768px){
            font-size: 6px;
        }
    }
    @each $name, $color in $c {
        --#{$name}: #{$color};
    }
}
[mode='dark'], .fancybox-active {
    @include over(b, d);
}
* {
    margin: 0;
    border: 0;
    padding: 0;
    outline: 0;
    border-radius: 0;
    background: none;
    vertical-align: top;
    -webkit-font-smoothing: subpixel-antialiased;
    -webkit-tap-highlight-color: transparent;
    &, &:before, &:after {
        box-sizing: border-box;
    }
}
a {
    color: inherit;
    text-decoration: none;
    transition: $modeTransition color, $modeTransition background;
}
img, svg {
    display: block;
    max-width: 100%;
}
button,
input,
optgroup,
select,
textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    font: inherit;
    appearance: none;
    display: block;
    color: inherit;
    @include placeholder {
        opacity: 1;
        font-family: inherit;
    }
}
html {
    font: 100 var(--main-font-size) / 1.714 $f;
    -webkit-text-size-adjust: 100%;
    height: 100%;
    max-width: 100vw;
    background: var(--page-background, #{c(b)});
    color: sc(b);
    user-select: none;
    transition: $modeTransition background, $modeTransition color;
    &.has-scroll-smooth, &.has-horizontal-scroll {
        overscroll-behavior: none none;
    }
    &.has-horizontal-scroll {
        overflow-x: auto;
        overflow-y: hidden;
        @include tm {
            overflow: auto;
        }
    }
}
body {
    font-size: rem(14);
    min-height: 100%;
    display: flex;
    background: var(--page-background, #{c(b)});
    color: sc(b);
    transition: $modeTransition background, $modeTransition color;
}
#root {
    width: 100%;
    display: flex;
    background: c(b);
    color: sc(b);
}
li {
    display: block;
}
.absolutely-splash {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
body {
    ::-webkit-scrollbar {
        width: map-get($scrollbar, 'size');
        height: map-get($scrollbar, 'size');
        &-button, &-corner {
            display: none;
        }
        &-thumb {
            border: map-get($scrollbar, 'space') solid transparent;
            border-radius: map-get($scrollbar, 'size');
            background: map-get($scrollbar, 'thumbBackground') content-box;
        }
        &-track {
            background: transparent;
        }
    }
}
.position-relative {
    position: relative;
}
