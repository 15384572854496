@import 'magic';
nav, aside {
    position: fixed;
    z-index: 10;
    width: rem($gap);
    top: 0;
    height: var(--window-height, 100vh);
    display: flex;
    transition: $modeTransition color, $modeTransition height, 400ms padding;
    mix-blend-mode: difference;
    @include over(b, staticWhite);
    pointer-events: none;
    transform: translate3d(0, 0, 0);
    @include mobile {
        width: auto;
        padding: 0 0 env(safe-area-inset-bottom);
    }
    > ul {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 1fr auto;
        padding: rem(50) 0;
        @include mobile {
            padding: rem(24) 0;
        }
        > li {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
        }
    }
    @at-root {
        &, #root {
            opacity: 0 !important;
            html.currently-loading & {
                opacity: 0 !important;
                transition: 300ms opacity, $modeTransition background, $modeTransition color, 400ms height;
            }
            html.loading-done & {
                opacity: 1 !important;
                transition: 50ms opacity, $modeTransition background, $modeTransition color, 400ms height;
            }
        }
    }
    a {
        pointer-events: auto;
    }
}
main {
    min-width: 100%;
    max-width: 100%;
    flex-shrink: 0;
    @include mobile {
        width: 100%;
        transition: 400ms padding;
        padding: 0 0 env(safe-area-inset-bottom);
    }
    .has-horizontal-scroll & {
        display: flex;
    }
}
section {
    min-height: 100vh;
    transition: 300ms min-height;
    @include mobile {
        min-height: var(--window-height-once, 100vh);
        transition: none;
    }
    @media screen and (orientation: portrait) {
        &.show-case-holder {
            min-height: 50vh;
            @include mobile {
                min-height: inherit;
                padding: rem(50) 0;
            }
        }
    }
    &.show-case-holder {
        &:last-child {
            @include desktop {
                margin-bottom: rem(150);
            }
            @media screen and (max-width: 1024px) {
                margin-bottom: rem(100);
            }
            @include mobile {
                margin-bottom: 0;
            }
        }
    }
    display: flex;
    max-width: 100%;
    padding: rem(50) rem($gap);
    @include mobile {
        padding: rem(36) 0;
    }
}
